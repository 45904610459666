import * as React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
  Stack,
  Grid,
  Button,
  SvgIcon,
  Box,
} from "@material-ui/core";
import birdOfParadiseLogoDiscountImg from "../static/images/Birds_of_Paradise_home_logo.png";
import { makeStyles } from "@material-ui/styles";
import { Trans, useTranslation } from "react-i18next";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const useStyles = makeStyles((theme) => ({
  image: {
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    margin: "auto",
  },
}));

export default function DiscountDialog() {
  const classes = useStyles();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 6,
          width: smDown ? "100%" : "undefined",
          margin: 1,
        },
      }}
    >
      <DialogContent style={{ background: "white" }}>
        <Box sx={{ position: "relative" }}>
          <SvgIcon
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
            }}
            color="disabled"
            onClick={handleClose}
          >
            <CloseRoundedIcon />
          </SvgIcon>
          <img
            alt="birds-of-paradise-home-logo"
            src={birdOfParadiseLogoDiscountImg}
            className={classes.image}
            style={{
              width: smDown ? "14%" : "8%",
              height: "auto",
              marginBottom: "30px",
            }}
          />
        </Box>
        <Stack gap={8}>
          <Stack alignItems="center" gap={4}>
            <Typography
              variant="h3"
              fontWeight="bold"
              textAlign="center"
              whiteSpace="pre-wrap"
            >
              {t("dialogTitle")}
            </Typography>
            <Typography color="text.secondary" textAlign="center">
              <Trans i18nKey="dialogSubtitle" />
            </Typography>
          </Stack>
          <Stack>
            <Typography fontWeight="bold" variant="h4" sx={{ mb: 2 }}>
              {t("dialogContent1")}
            </Typography>
            <Typography>
              <Trans i18nKey="dialogContent2" />
            </Typography>
            <Typography>
              <Trans i18nKey="dialogContent3" />
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <Trans i18nKey="dialogContent6" />
            </Typography>
            <Typography>
              <Trans i18nKey="dialogContent8" />
            </Typography>
            <Typography>
              <Trans i18nKey="dialogContent9" />
            </Typography>
            <Typography>
              <Trans i18nKey="dialogContent10" />
            </Typography>
          </Stack>
          <Stack>
            <Typography fontWeight="bold" variant="h4" sx={{ mb: 2 }}>
              {t("dialogContent12")}
            </Typography>
            <Typography>
              <Trans i18nKey="dialogContent13" />
            </Typography>
            <Typography>
              <Trans i18nKey="dialogContent14" />
            </Typography>
          </Stack>
          {/* <Stack>
            <Typography fontWeight="bold" variant="h4" sx={{ mb: 2 }}>
              {t("dialogContent2")}
            </Typography>
            <Typography>
              <Trans i18nKey="dialogContent3" />
            </Typography>
            <Typography >
              <Trans i18nKey="dialogContent4" />
            </Typography>
            <Typography >
              <Trans i18nKey="dialogContent5" />
            </Typography>
            <Typography >
              <Trans i18nKey="dialogContent6" />
            </Typography>
            <Typography variant="caption" textAlign="justify" sx={{ mt: 1 }}>
              {t("dialogContent1")}
            </Typography>
          </Stack> */}
          {/* <Stack gap={4}>
            <Typography variant="h4" fontWeight="bold">
              {t("dialogContent8")}
            </Typography>
            <Stack>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("dialogContent9")}
              </Typography>
              <Typography color="text.secondary">
                {t("dialogContent10")}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("dialogContent11")}
              </Typography>
              <Typography color="text.secondary">
                {t("dialogContent12")}
              </Typography>
              <Typography color="text.secondary">
                {t("dialogContent13")}
              </Typography>
              <Typography color="text.secondary">
                {t("dialogContent14")}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("dialogContent15")}
              </Typography>
              <Typography color="text.secondary">
                {t("dialogContent16")}
              </Typography>
              <Typography color="text.secondary">
                {t("dialogContent17")}
              </Typography>
              <Typography color="text.secondary">
                {t("dialogContent18")}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("dialogContent19")}
              </Typography>
              <Typography color="text.secondary">
                {t("dialogContent20")}
              </Typography>
            </Stack>
          </Stack> */}
        </Stack>
        <Grid
          container
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={2}
          sx={{ mt: 5 }}
        >
          <a
            style={{ flexGrow: 1 }}
            href="https://www.motibro.com/login?club=birds-of-paradise"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              fullWidth
              size="small"
              variant="outlined"
              style={{ borderRadius: "18px", textTransform: "none" }}
              onClick={handleClose}
            >
              {t("navigation.registration")}
            </Button>
          </a>
          <a
            style={{ flexGrow: 1 }}
            color="default"
            href="https://www.motibro.com/visitors/reservation_widget?operator=birds-of-paradise"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              fullWidth
              variant="contained"
              size="small"
              style={{ background: "#95436c", borderRadius: "18px" }}
              onClick={handleClose}
            >
              {t("classes3")}
            </Button>
          </a>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
